import React, { useContext, useEffect, useState} from 'react';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import BaseHD from '../../assets/images/base-hd.png';

import LanguageContext from '../../contexts/language';

export default function PageWelcome() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [video, setVideo] = useState([]);
    const [loading, setLoading] = useState(true);
    
    async function loadPage(){
        const response = await api.post(`/welcome?lang=${lang}`);

        setVideo(response.data.content.video);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">
                                {language.menu_welcome}
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col size={{ md: 12 }} className="m-auto" textAlign="justify">
                            {loading ?
                            <div className="content-video">
                                <Shimmer source={BaseHD} rounded={6} />
                            </div>:
                            <div className="content-video">
                                {video.id=='' ?
                                <Image shadow="2" rounded="12px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${video.banner})`}} />:
                                <div className="embed-container">
                                    <iframe src={`https://player.vimeo.com/video/${video.id}?title=0&byline=0&portrait=0&autoplay=1`} frameborder="0" allowfullscreen></iframe>
                                </div>
                                }
                            </div>}
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}