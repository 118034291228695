import React, { useContext, useState } from 'react';
import { Link, useQuery, useLocation, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { HiChevronDown } from 'react-icons/hi';
import api from '../../services/api';
import { Div, Text, Container, Row, Col, Dropdown, Anchor, Input, Button, Icon, Notification, Label, Checkbox } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import flag_brazil from '../../assets/images/flag-brazil.png';
import flag_usa from '../../assets/images/flag-usa.png';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function Login(props) {
    
    const { signed, signIn } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const history = useHistory();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');

    const [phoneLang, setPhoneLang] = useState(lang);
    const [terms, setTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    function setViewPassword(){
        showPassword ? setShowPassword(false) : setShowPassword(true);
    }

    const [countryCode, setCountryCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    function setViewDropdown(){
        showDropdown ? setShowDropdown(false) : setShowDropdown(true);
    }

    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [showDropdownState, setShowDropdownState] = useState(false);
    function setViewDropdownState(){
        showDropdownState ? setShowDropdownState(false) : setShowDropdownState(true);
    }

    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [showDropdownCity, setShowDropdownCity] = useState(false);
    function setViewDropdownCity(){
        showDropdownCity ? setShowDropdownCity(false) : setShowDropdownCity(true);
    }

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    
    async function submitCreateAccount(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name=="" ||
                email=="" ||
                password==""
            ){
                scrollTop();
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_data);
                return;
            }else if(terms==false){
                scrollTop();
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_terms);
                return;
            }

            const response = await api.post('/register', {
                name,
                email,
                password,
            });

            if(response.data.status==='success'){
                const sign = await signIn(
                    email,
                    password
                );
    
                if (!sign.user) {
                    scrollTop();
                    setLoading(false);
                    setError(true);
                    setErrorMessage(language.create_error);
                    return;
                }
                
                setLoading(false);
                setError(false);
                setTerms(false);
                
                if(query.get('redirect')==null || query.get('redirect')=='null' || query.get('redirect')==''){
                    history.push('/');
                }else{
                    history.push(query.get('redirect'));
                }
            }else if(response.data.status==='error'){
                scrollTop();
                setLoading(false);
                setError(true);
                setErrorMessage(response.data.data);
                return;
            }else if(response.data.status==='exist'){
                scrollTop();
                setLoading(false);
                setError(true);
                setErrorMessage(language.email_error);
                return;
            }

        }catch(err){
            scrollTop();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);
        }
    }

    function checkToogle(){
        terms ? setTerms(false) : setTerms(true);
    }

    function scrollTop() {
        window.scrollTo(0, 0);
    }

    return (
        <div className="custom-page">
            <Header />

            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                            <form onSubmit={submitCreateAccount}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.create_account}
                                    </Text>
                                    <Text textSize="body" textColor="gray700">
                                        {language.has_account} <Link to={`/login?redirect=${query.get('redirect')}`} className="b-link">{language.form_login}</Link>
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <Input
                                        placeholder={language.form_name}
                                        type="text"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                    />

                                    <Input
                                        placeholder={language.form_email}
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                    />

                                    <Input
                                        placeholder={language.password}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        type={showPassword ? "text" : "password"}
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        suffix={
                                        <Button
                                            type="button"
                                            pos="absolute"
                                            onClick={setViewPassword}
                                            bg="transparent"
                                            w="3rem"
                                            top="0"
                                            right="0"
                                        >
                                            <Icon
                                            name={showPassword ? "EyeSolid" : "Eye"}
                                            color="gray900"
                                            size="16px"
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                            />
                                        </Button>
                                        }
                                    />
                                    
                                    <Div textSize="caption" textAlign="left" m={{ t: '20px' }}>
                                        <Label align="center" textWeight="600" m={{ b: "0.5rem" }}>
                                            <Checkbox
                                                onChange={checkToogle}
                                                checked={terms}
                                                activeColor="info800"
                                                size="22px"
                                            />
                                            {language.form_terms} <Anchor m={{l: '6px'}} className="b-link" target="_blank" href="/page/terms">{language.menu_terms}</Anchor>
                                        </Label>
                                        
                                    </Div>
                                </Div>

                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.form_creating}</>:
                                    <>{language.create_account}</>
                                    }
                                </Button>
                                
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Div>

            <Footer />
        </div>
        
    );
}