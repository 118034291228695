import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Dropdown, Image, Input, Button, Icon, Notification, Label, Checkbox } from "atomize";
import { FiShoppingCart } from "react-icons/fi";
import { ImCreditCard, ImPaypal } from "react-icons/im";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Base from '../../assets/images/blank-br.png';
import Livro from '../../assets/images/brazilinmiami.jpg';
import LivroSumario from '../../assets/images/brazilinmiami_sumario.jpg';
import BookSponsors from '../../assets/images/book_sponsors.jpg';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function PageBookFeatured() {

    const { signed, user, token } = useContext(AuthContext);
    const history = useHistory();

    const location = useLocation();

    const { language } = useContext(LanguageContext);

    const [paymentOptions, setPaymentOptions] = useState(false);

    function paymentOption(){
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }else{
            if(user.address=="" || user.address==null){
                setOpenAddress(true);
            }else{
                setPaymentOptions(true);
            }
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [openAddress, setOpenAddress] = useState(false);

    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');

    const [countryCode, setCountryCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    function setViewDropdown(){
        showDropdown ? setShowDropdown(false) : setShowDropdown(true);
    }

    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [showDropdownState, setShowDropdownState] = useState(false);
    function setViewDropdownState(){
        showDropdownState ? setShowDropdownState(false) : setShowDropdownState(true);
    }

    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [showDropdownCity, setShowDropdownCity] = useState(false);
    function setViewDropdownCity(){
        showDropdownCity ? setShowDropdownCity(false) : setShowDropdownCity(true);
    }

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    async function buyPaypal(){
        
        setError(false);
        setLoading(true);
        try{
            const response = await api.post(
                `/paypal?content=brazil-in-miami`
            , {}, config);

            setError(false);
            window.location.href = response.data.url;

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
        }
    }
    async function buyCreditCard(){
        setError(false);
        setLoading(true);
        try{
            const response = await api.post(
                `/square?content=brazil-in-miami`
            , {}, config);

            setError(false);
            window.location.href = `/payment-square/${response.data.payment_id}`;

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
        }
    }

    async function saveAddress(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(address=="" ||
                number=="" ||
                neighborhood=="" ||
                countryName=="" ||
                stateName=="" ||
                cityName==""
            ){
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_data);
                return;
            }

            const response = await api.post(`/country?id=${user.id_image}`, {
                address,
                number,
                neighborhood,
                country: countryName,
                state: stateName,
                city: cityName,
            });

            const new_content_user = {
                name: user.name,
                email: user.email,
                type: user.type,
                id_image: user.id_image,
                image: user.image,
                address: address,
                number: number,
                neighborhood: neighborhood,
                country: countryName,
                state: stateName,
                city: cityName
            }
            localStorage.setItem('user', JSON.stringify(new_content_user));

            setPaymentOptions(true);
            setOpenAddress(false);
            setLoading(false);
            setError(false);

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);
        }
    }

    const listCountry = (
        <Div shadow="3" rounded="6px" overflow="scroll">
            <Button
                type="button"
                w="100%"
                h="38px"
                justify="flex-start"
                textWeight="600"
                textSize="body"
                textColor="info900"
                bg="white"
                hoverBg="info200"
                rounded="0"
                onClick={() => {
                    setShowDropdown(false);
                    setCountryCode(31);
                    setCountryName(language.country_br);
                    setStateCode('');
                    setStateName('');
                    setCityCode('');
                    setCityName('');
                }}
            >
                {language.country_br}
            </Button>
            <Button
                type="button"
                w="100%"
                h="38px"
                justify="flex-start"
                textWeight="600"
                textSize="body"
                textColor="info900"
                bg="white"
                hoverBg="info200"
                rounded="0"
                onClick={() => {
                    setShowDropdown(false);
                    setCountryCode(233);
                    setCountryName(language.country_us);
                    setStateCode('');
                    setStateName('');
                    setCityCode('');
                    setCityName('');
                }}
            >
               {language.country_us}
            </Button>
        </Div>
    );

    const listState = (
        <Div h="200px" shadow="3" rounded="6px" overflow="scroll">
            
        </Div>
    );

    const listCity = (
        <Div h="200px" shadow="3" rounded="6px" overflow="scroll">
            
        </Div>
    );

    return (
        <>
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ xs: 12, md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '60px' }} textSize="display2" textColor="black800">
                                {language.title_bookfeatured}
                            </Text>
                        </Col>
                    </Row>
                    <Row d="flex" justify="center">
                        <Col size={{ xs: 12, md: 8 }}>
                            <Image shadow="3" rounded="12px" src={Base} className="cover-bg" style={{backgroundImage: `url(${Livro})`}} />
                        </Col>
                        <Col size={{ xs: 12, md: 8 }}>
                            <Row justify="center">
                                <Col size={{ md: 8 }}>
                                    <Text m={{t: '30px', b: '20px'}} textAlign="justify" textColor="gray900" textSize="body">
                                        {language.text_bookfeatured}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col size={{ xs: 12, md: 8 }} p={{ t: "10px", b: "80px"}} d="flex" flexDir="column" align="center" justify="center" className={`${ loading ? 'loading-sending' : '' }`}>
                            <Text m={{t: '20px', b: '10px'}} textAlign="justify" textColor="success700" textSize="subheader">
                                $55.00 + $6.50 {language.shipping}
                            </Text>

                            {paymentOptions &&
                            <Text m={{t: '0px', b: '4px'}} textTransform="uppercase" textAlign="justify" textColor="black200" textSize="paragraph">
                                {language.pay_with}:
                            </Text>
                            }
                            
                            {openAddress ?
                            <form onSubmit={saveAddress}>
                                <Div p={{ b: '30px' }} w="350px">
                                    <Dropdown
                                        isOpen={showDropdown}
                                        onClick={setViewDropdown}
                                        menu={listCountry}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        m={{ t: '20px' }}
                                    >
                                        {countryName==''?
                                            <>{language.form_country}</>:
                                            <>{countryName}</>
                                        }
                                    </Dropdown>
                                    
                                    {countryCode!='' &&
                                    <Dropdown
                                        isOpen={showDropdownState}
                                        onClick={setViewDropdownState}
                                        menu={listState}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        m={{ t: '20px' }}
                                    >
                                        {stateName==''?
                                            <>{language.form_state}</>:
                                            <>{stateName}</>
                                        }
                                    </Dropdown>
                                    }
                                    {stateCode!='' &&
                                    <Dropdown
                                        isOpen={showDropdownCity}
                                        onClick={setViewDropdownCity}
                                        menu={listCity}
                                        focusBorderColor="info800"
                                        rounded="lg"
                                        m={{ t: '20px' }}
                                    >
                                        {cityName==''?
                                            <>{language.form_city}</>:
                                            <>{cityName}</>
                                        }
                                    </Dropdown>
                                    }
                                    {cityCode!='' &&
                                    <>
                                        <Input
                                            placeholder={language.form_neighborhood}
                                            type="text"
                                            value={neighborhood}
                                            onChange={e => setNeighborhood(e.target.value)}
                                            focusBorderColor="info800"
                                            rounded="lg"
                                            m={{ t: '20px', b: '20px' }}
                                            p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        />

                                        <Input
                                            placeholder={language.form_address}
                                            type="text"
                                            value={address}
                                            onChange={e => setAddress(e.target.value)}
                                            focusBorderColor="info800"
                                            rounded="lg"
                                            m={{ b: '20px' }}
                                            p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        />

                                        <Input
                                            placeholder={language.form_number}
                                            type="text"
                                            value={number}
                                            onChange={e => setNumber(e.target.value)}
                                            focusBorderColor="info800"
                                            rounded="lg"
                                            m={{ b: '20px' }}
                                            p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        />
                                        <Button
                                            type="submit"
                                            w="100%"
                                            h="44px"
                                            textWeight="600"
                                            textSize="body"
                                            textColor="info700"
                                            hoverTextColor="info900"
                                            bg="info200"
                                            hoverBg="info300"
                                            rounded="lg"
                                            disabled={loading}
                                        >
                                            {
                                            loading ?
                                            <><Icon
                                                name="Loading"
                                                size="22px"
                                                color="info700"
                                                m={{r: '6px'}}
                                            /> {language.form_saving}</>:
                                            <>{language.form_save}</>
                                            }
                                        </Button>
                                    </>
                                    }
                                </Div>
                            </form>:
                            <>
                                {paymentOptions ?
                                <Div d="flex" flexDir="row">
                                    <Button
                                        type="button"
                                        onClick={buyPaypal}
                                        prefix={
                                            <ImPaypal size={18} style={{marginRight: '6px'}} />
                                        }
                                        bg="info800"
                                        hoverBg="info900"
                                        shadow="2"
                                        hoverShadow="3"
                                        cursor="pointer"
                                    >
                                        {language.paypal}
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={buyCreditCard}
                                        prefix={
                                            <ImCreditCard size={18} style={{marginRight: '6px'}} />
                                        }
                                        bg="black800"
                                        hoverBg="dark"
                                        shadow="2"
                                        hoverShadow="3"
                                        cursor="pointer"
                                        m={{l: '10px'}}
                                    >
                                        {language.credit_card}
                                    </Button>
                                </Div>:
                                <Button
                                    type="button"
                                    onClick={paymentOption}
                                    suffix={
                                        <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                    }
                                    bg="black600"
                                    hoverBg="black400"
                                    shadow="2"
                                    hoverShadow="3"
                                    cursor="pointer"
                                >
                                    {language.buy}
                                </Button>
                                }
                            </>
                            }
                        </Col>
                        <Col size={{ xs: 12, md: 8 }}>
                            <Image shadow="2" rounded="12px" src={LivroSumario} />
                        </Col>
                        <Col p={{ t: "100px"}} d size={{ xs: 12, md: 7 }}>
                            <Image src={BookSponsors} />
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}