import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import LanguageContext from '../../contexts/language';

import BaseHD from '../../assets/images/base-hd.png';

export default function PageSchedule() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');
    
    const [page, setPage] = useState([]);
    const [loading, setLoading] = useState(true);
    
    async function loadPage(){
        const response = await api.post(`/schedule?lang=${lang}`);
        setPage(response.data.content.day ? response.data.content.day : []);
        setLoading(false);
    }
    
    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    const [calendarModal, setCalendarModal] = useState(false);
    const [calendarLink, setCalendarLink] = useState('');
    function openCalendar(link){
        setCalendarModal(true);
        setCalendarLink(link);
    }
    function closeCalendar(){
        setCalendarModal(false);
        setCalendarLink('');
    }
    function addCalendar(){
        window.open(calendarLink, '_blank');
        setCalendarModal(false);
        setCalendarLink('');
    }

    return (
        <>
            <Header />
            {calendarModal &&
            <div className="google-calendar-overlay">
                <div className="google-calendar-wrapper">
                    <div className="google-calendar-title">{language.google_calendar}</div>
                    <div className="google-calendar-question">{language.google_calendar_text}</div>
                    <div className="google-calendar-yes">
                        <a onClick={addCalendar}>{language.google_calendar_yes}</a>
                    </div>
                    <div className="google-calendar-not">
                        <a onClick={closeCalendar}>{language.google_calendar_not}</a>
                    </div>
                </div>
            </div>}
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '60px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">
                                {
                                loading ?
                                <Shimmer width={300} height={30} />:
                                <>{language.menu_schedule}</>
                                }
                                </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ md: 8 }} className="m-auto" textAlign="justify">
                            {
                                loading ?
                                <>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={350} />
                                        <Shimmer />
                                        <Shimmer width={100} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                        <Shimmer />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={300} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                </>:
                                <>
                                    {page.map((item, index) => (
                                    <Div key={`date-${item.id}`} className="content-post" textSize="paragraph" textColor="gray900">
                                        <div className="content-text schedule-text">
                                            <h1>{item.date}</h1>
                                            {Array.isArray(page[index].post) && <>
                                            {page[index].post.map((item_post, index_post) => (
                                                <div key={`text-${index_post}`} className="schedule-text-content">
                                                    <Link to={`/event/${item_post.url}`}>
                                                        <Image shadow="3" rounded="12px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item_post.image})`}} />
                                                    </Link>
                                                    <Link className="link-title" to={`/event/${item_post.url}`}>{item_post.title}</Link>
                                                    <p>
                                                        {item_post.time} Miami<br />
                                                        {item_post.time_br} Brasília
                                                    </p>
                                                    <p dangerouslySetInnerHTML={{__html: item_post.text}} />
                                                    {item_post.calendar=='yes' && <a className="link-calendar" onClick={() => openCalendar(`https://calendar.google.com/calendar/r/eventedit?text=${item_post.title}&dates=${item_post.time_start_calendar}/${item_post.time_end_calendar}`)}>{language.google_calendar_btn}</a>}
                                                </div>))}
                                            </>}
                                        </div>
                                    </Div>
                                    ))}
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}