import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Button } from "atomize";
import { Carousel } from '@trendyol-js/react-carousel';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';

import LanguageContext from '../contexts/language';
import BaseHD from '../assets/images/base-hd.png';
import Tutoral from '../assets/images/tutorial.jpg';

export default function Now(props) {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');
    const [isActive, setIsActive] = useState(false);

    const { content, isHome } = props;

    function startOnDate(){
        dayjs.extend(isBetween);
        let now = dayjs();
        if(lang=='pt') {
            if(now.isBetween('2021-11-21 09:45:00', '2022-11-20 12:45:00')) {
                setIsActive(true);
            }
        }else{
            if(now.isBetween('2021-11-21 11:45:00', '2022-11-20 10:45:00')) {
                setIsActive(true);
            }
        }
    }
    useEffect(() => {
        startOnDate();
    }, []);
    
    return (
        <>
        
        <Div bg="white" p={{ t: isHome ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row align="center" justify="center" className="mobile-hidden">
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '20px' }} textSize={isActive ? "display1" : "title"} textColor="black800" textAlign="left">{language.title_panel}</Text>
                    </Col>
                </Row>
                <Row align="center" justify="center">
                    {content.slice(0, 1).map(item => (
                    <Col key={item.id} size={{ md: 6 }}>
                        <Text p={{ b: '20px' }} textSize={isActive ? "display1" : "title"} textColor="black800" textAlign="left" className="mobile-view">{language.title_panel}</Text>
                        <Link to={`/event/${item.url}`} className="no-underline menu-opacity">
                            <Image shadow="3" rounded="20px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center">{item.title}</Text>
                            <Text textSize="paragraph" textColor="gray800" textAlign="center" dangerouslySetInnerHTML={{__html: item.text}} />
                        </Link>
                    </Col>
                    ))}
                    <Col size={{ md: 6 }}>
                        <Row align="center" justify="center" p={{ t: '-20px', b: '20px', l: '20px', r: '20px' }}>
                            <Carousel
                                show={1.5}
                                slide={1}
                                transition={1}
                                infinite
                                rightArrow={<div className="arrow-carousel arrow-right"><FiChevronRight size={28} /></div>}
                                leftArrow={<div className="arrow-carousel arrow-left"><FiChevronLeft size={28} /></div>}
                            >
                                {content.slice(1, 100).map(item => (
                                <Col key={item.id} p={{ r: '20px' }} size={{ md: 12 }}>
                                    <Link to={`/event/${item.url}`} className="no-underline menu-opacity">
                                        <Image shadow="3" rounded="10px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                                        <Text p={{ t: '6px' }} textSize="body" textColor="black600" className="text-line">{item.title}</Text>
                                    </Link>
                                </Col>
                                ))}
                            </Carousel>
                        </Row>
                    </Col>
                </Row>
                <Row p={{ t: '120px' }} align="center" justify="center">
                    <Col size={{ xs: 12, md: 6 }}>
                        <Link to="/tutorial" className="no-underline menu-opacity">
                            <Image shadow="3" rounded="20px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${Tutoral})`}} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center">{language.tutorial}</Text>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Div>
        </>
    );
}