import React from 'react';
import { Div, Text, Container, Row, Col } from "atomize";
import ReactPlayer from 'react-player';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function PageMiami() {

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">
                                Miami
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col size={{ md: 12 }} className="m-auto" textAlign="justify">
                            <div className="content-video">
                                <div className="embed-container">
                                    <ReactPlayer
                                        url={`https://vimeo.com/646767394`}
                                        controls={true}
                                        playsinline={true}
                                        playing={true}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                                <Text p={{ t: '10px' }} textSize="subheader" textColor="gray700">Greater Miami Convention & Visitors Bureau</Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}