import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Button } from "atomize";
import { BiChevronRight } from "react-icons/bi";

import LanguageContext from '../contexts/language';

export default function Authors(props) {

    const { language } = useContext(LanguageContext);

    const { content, special, isHome, isSearch } = props;
    
    return (
        <>
        {content.length>0 &&
        <Div bg={special ? 'gray200' : 'white' } p={{ t: (isHome || isSearch) ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center">
                        {special ? language.title_author_special : language.title_participantes }
                        </Text>
                    </Col>
                </Row>
                <Row>
                    {content.map(item => (
                    <Col key={item.id} p="20px" size={{ xs: 6, md: 4 }}>
                        <Link to={`/author/${item.url}`} className="no-underline menu-opacity">
                            <Image shadow="3" rounded="12px" src={item.image} />
                            <Text p={{ t: '10px', b: '10px' }} textSize="title" textColor="black600" textAlign="center" className="text-line">{item.name}</Text>
                        </Link>
                        <Link to={`/author/${item.url}`} className="no-underline menu-opacity">
                            <Button
                                className="text-center m-auto"
                                textSize="caption"
                                textWeight="600"
                                h="34px"
                                bg="#8e1fc5"
                                hoverBg="#ad32eb"
                                shadow="2"
                                hoverShadow="3"
                                cursor="pointer"
                            >
                                {language.more}
                            </Button>
                        </Link>
                    </Col>
                    ))}
                </Row>
                {isHome &&
                <Row>
                    <Col size={{ md: 'auto' }} p={{ t: "40px", b: "40px"}} textAlign="center">
                        <Link to={`${special ? '/special-participants': '/authors'}`} className="btn_all">
                            {language.btn_all} <BiChevronRight size={22} style={{marginLeft: '6px'}} />
                        </Link>
                    </Col>
                </Row>
                }
            </Container>
        </Div>
        }
        </>
    );
}