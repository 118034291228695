import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Modal, Button, Icon, Anchor, Notification, scrollTo } from "atomize";
import { FiPlay, FiShoppingCart } from 'react-icons/fi';
import ReactPlayer from 'react-player';

import dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import BaseHD from '../../assets/images/base-hd.png';
import BaseSquare from '../../assets/images/blank-600.png';
import image_default from '../../assets/images/default.png';

import logos_video from '../../assets/images/logos_video.png';
import logos_video_en from '../../assets/images/logos_video_en.png';

import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function PageEvent() {

    const { id } = useParams();

    const { signed, user, token } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [timer, setTimer] = useState(0);
    const [isActive, setIsActive] = useState(false)
    const increment = useRef(null);

    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language, id]);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };
    
    const [post, setPost] = useState([]);
    const [post_items, setPostItems] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [mediadora, setMediadora] = useState([]);
    const [book, setBook] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [textAbout, setTextAbout] = useState('');
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const [loadingCmm, setLoadingCmm] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');

    const [time, setTime] = useState(Date.now());
    
    async function loadPage(){
        const response = await api.post(`/event?id=${id}&lang=${lang}&v=2`);

        setPost(response.data.content.post[0]);
        setPostItems(response.data.content.post[0].post_items ? response.data.content.post[0].post_items : []);
        setParticipantes(response.data.content.post[0].participantes ? response.data.content.post[0].participantes : []);
        setMediadora(response.data.content.post[0].mediadora ? response.data.content.post[0].mediadora : []);
        setBook(response.data.content.post[0].book ? response.data.content.post[0].book : []);
        loadComment(response.data.content.post[0].id);

        setLoading(false);
    }


    async function loadComment(id_post){
        const response = await api.post(`/comments?id=${id_post}`);
        setComments(response.data.content.comments ? response.data.content.comments : []);
        setLoadingCmm(false);
    }

    async function addComments(e){
        e.preventDefault();
        setLoadingCmm(true);
  
        if(comment==""){
            setLoadingCmm(false);
            setError(true);
            setErrorMessage(language.comment_error);
            return;
        }

        await api.post(`/add-comment?id=${post.id}&comment=${comment}`, {}, config);
        setComment('');
        setSuccess(true);
        setSuccessMessage(language.comment_success);
        loadComment(post.id);
        scrollTo("#topComment");

    }

    function writeComment(e){

        if(e.key === 'Enter'){
        	addComments(e);
        }else{
        	return true;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());
            if(isActive){
                loadComment(post.id);
            }
        }, 20000);
        return () => {
            clearInterval(interval);
        };
    }, [post]);

    const [calendarModal, setCalendarModal] = useState(false);
    const [calendarLink, setCalendarLink] = useState('');
    function openCalendar(link){
        setCalendarModal(true);
        setCalendarLink(link);
    }
    function closeCalendar(){
        setCalendarModal(false);
        setCalendarLink('');
    }
    function addCalendar(){
        window.open(calendarLink, '_blank');
        setCalendarModal(false);
        setCalendarLink('');
    }

    useEffect(() => {
        setIsActive(true);
        setTimer(0);

        if(!isActive){
            initTimer();
        }
        return () => {
            clearInterval(increment.current);
        };

    }, [post, timer, isActive]);

    function initTimer(){
        startOnDate();
        increment.current = setInterval(() => {
            setTimer((timer) => timer + 1);
        }, 2000);
    }

    function startOnDate(){
        dayjs.extend(isBetween);
        let now = dayjs();
        if (now.isBetween(post.time_start, post.time_end)) {
            if(!signed){
                history.push(`/login?redirect=${location.pathname}`);
                return;
            }
            setIsActive(true);
            setTimer(0);

            clearInterval(increment.current);
            return;
        }
    }

    function validLogin(){
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
            return;
        }
    }

    return (
        <>
            <Header />
            {calendarModal &&
            <div className="google-calendar-overlay">
                <div className="google-calendar-wrapper">
                    <div className="google-calendar-title">{language.google_calendar}</div>
                    <div className="google-calendar-question">{language.google_calendar_text}</div>
                    <div className="google-calendar-yes">
                        <a onClick={addCalendar}>{language.google_calendar_yes}</a>
                    </div>
                    <div className="google-calendar-not">
                        <a onClick={closeCalendar}>{language.google_calendar_not}</a>
                    </div>
                </div>
            </div>}
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Notification
                bg="success700"
                isOpen={success}
                onClose={() => setSuccess(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {success_message}
            </Notification>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)} align="start" rounded="md" maxW="48rem">
                <Icon
                    name="Cross"
                    pos="absolute"
                    top="10px"
                    right="10px"
                    size="24px"
                    onClose={() => setShowModal(false)}
                    onClick={() => setShowModal(false)}
                    cursor="pointer"
                />
                <Div d="flex">
                    <Text textAlign="justify" textSize="subheader">
                        <div className="text-html" dangerouslySetInnerHTML={{__html: textAbout}} />
                    </Text>
                </Div>
            </Modal>

            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{xs: 12,  md: 12 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '20px' }} textSize="display2" textColor="black800">
                                {
                                loading ?
                                <Shimmer width={300} height={30} />:
                                <span dangerouslySetInnerHTML={{__html: post.title}} />
                                }
                            </Text>
                            <Text p={{ b: '10px' }} textSize="title" textColor="gray800">
                                {
                                loading ?
                                <Shimmer width={250} height={26} />:
                                <>{post.date}</>
                                }
                            </Text>
                            <Text p={{ b: '20px' }} textSize="subheader" textColor="black500" className="event_hour">
                                {
                                loading ?
                                <Shimmer width={200} height={22} />:
                                <>
                                    {post.time} Miami<br />
                                    <span>{post.time_br} Brasília</span>
                                </>
                                }
                            </Text>
                            {
                            loading ?
                            <Shimmer width={180} height={34} />:
                            <>{post.calendar=='yes' && <a className="link-calendar" onClick={() => openCalendar(`https://calendar.google.com/calendar/r/eventedit?text=${post.title}&dates=${post.time_start_calendar}/${post.time_end_calendar}`)}>{language.google_calendar_btn}</a>}</>}
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ xs: 12, md: 12 }} className="m-auto">
                            {
                                loading ?
                                <>
                                    <p className="mb-4">
                                        <Shimmer source={BaseHD} rounded={6} />
                                    </p>
                                </>:
                                <>
                                        <div className="content-video">
                                            <div className="embed-container">
                                                {(post.video==null || post.video=='') ?
                                                <>
                                                    <Div>
                                                        <Image shadow="3" rounded="6px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${post.banner})`}} />
                                                    </Div>
                                                </>:
                                                <>
                                                    {post.video_type==='vimeo' &&
                                                    <ReactPlayer
                                                        url={`https://vimeo.com/${post.video}`}
                                                        controls={true}
                                                        playsinline={true}
                                                        playing={true}
                                                        frameBorder="0"
                                                        allowFullScreen
                                                    />}
                                                    {post.video_type==='youtube' &&
                                                    <ReactPlayer
                                                        url={`https://www.youtube.com/watch?v=${post.video}`}
                                                        controls={true}
                                                        playsinline={true}
                                                        playing={true}
                                                        frameBorder="0"
                                                        allowFullScreen
                                                    />}
                                                    {post.video_type==='facebook' &&
                                                    <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${post.video}`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                </>
                                                }
                                                
                                            </div>
                                            <Image src={lang=='pt' ? logos_video : logos_video_en } />
                                        </div>
                                    {
                                    post.video_2!='' &&
                                        <div className="content-video">
                                            <div className="embed-container">
                                                {
                                                post.video_type_2==='vimeo' &&
                                                <iframe src={`https://player.vimeo.com/video/${post.video_2}?title=0&byline=0&portrait=0&autoplay=1`} frameborder="0" allowfullscreen></iframe>
                                                }
                                                {
                                                post.video_type_2==='youtube' &&
                                                <iframe src={`https://www.youtube.com/embed/${post.video_2}?rel=0&showinfo=0&autoplay=1`} allow='autoplay' frameborder="0" allowfullscreen></iframe>
                                                }
                                                {
                                                post.video_type_2==='facebook' &&
                                                <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${post.video_2}`} frameborder="0" allowfullscreen></iframe>
                                                }
                                            </div>
                                            <Image src={lang=='pt' ? logos_video : logos_video_en } />
                                        </div>
                                    }
                                </>
                            }
                        </Col>
                        <Col p="20px" size={{ xs: 12, md: 8 }} className="m-auto">
                            {loading ?
                            <>
                                <p className="mb-4">
                                    <Shimmer />
                                    <Shimmer width={350} />
                                    <Shimmer />
                                    <Shimmer width={100} />
                                </p>
                                <p className="mb-4">
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer width={200} />
                                    <Shimmer />
                                </p>
                                <p className="mb-4">
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer width={300} />
                                </p>
                                <p className="mb-4">
                                    <Shimmer />
                                    <Shimmer width={400} />
                                    <Shimmer />
                                    <Shimmer width={200} />
                                </p>
                                <p className="mb-4">
                                    <Shimmer />
                                    <Shimmer width={400} />
                                    <Shimmer />
                                    <Shimmer width={200} />
                                </p>
                            </>:
                            <>
                                <Div className="content-post" textSize="paragraph" textColor="gray900">
                                    {comments.length>0 &&
                                    <>
                                    <Div m={{b: '10px'}} id="topComment">
                                        <Text textSize="title" textColor="black700" textAlign="center">{language.comments}</Text>
                                    </Div>
                                    <Div bg="gray100" rounded="12px" p={{l:'20px', r:'20px', t:'10px', b:'10px'}} shadow="3" maxH="800px" overflow="scroll">
                                        <Row className="row-comment">
                                            {comments.map(item => (
                                            <>
                                                <Col key={item.id} size="12" d="flex" align="flex-start" justify="flex-start">
                                                    <Div>
                                                        <Image shadow="1" w="48px" m={{r: '10px'}} rounded="circle" src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${item.image==null ? image_default : item.image})`}} />
                                                    </Div>
                                                    <Div>
                                                        <Div><Text tag="span" textColor="black700">{item.name}</Text></Div>
                                                        <Div><Text tag="span" textColor="gray900" dangerouslySetInnerHTML={{__html: item.comment}} /></Div>
                                                    </Div>
                                                </Col>
                                                <div className="divider-comment"></div>
                                            </>
                                            ))}
                                        </Row>
                                    </Div>
                                    <Div h="40px"></Div>
                                    </>}

                                    {isActive &&
                                    <>
                                    <form onSubmit={addComments}>
                                        <Div className={`${ loadingCmm ? 'loading-sending' : '' }`}>
                                            <Row>
                                                <Col size="12" w="100%" d="flex" align="center" justify="flex-start" flexDir="column" onClick={validLogin}>
                                                    <textarea value={comment} onKeyPress={writeComment} onChange={e => setComment(e.target.value)} className="custom-input textarea" />
                                                    <Button
                                                        type="submit"
                                                        h="44px"
                                                        textWeight="600"
                                                        textSize="body"
                                                        textColor="info700"
                                                        hoverTextColor="info900"
                                                        bg="info200"
                                                        hoverBg="info300"
                                                        rounded="lg"
                                                        m={{t: '20px'}}
                                                        p={{x: '40px'}}
                                                    >
                                                        {language.form_send}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Div>
                                    </form>
                                    <Div h="40px"></Div>
                                    </>}
                                </Div>

                                {post_items.map((item, index) => (
                                    <Div className="content-post" textSize="paragraph" textColor="gray900">
                                        {
                                        item.type==='image' &&
                                            <div className="content-image" key={`image-${index}`}>
                                                <Row>
                                                    <Col size={{ md: 12 }} className="m-auto" textAlign="justify">
                                                        <img src={item.image} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                        item.type==='text' &&
                                            <div className="content-text" key={`text-${index}`} dangerouslySetInnerHTML={{__html: item.text}} />
                                        }
                                        {
                                        item.type==='video' &&
                                            <div className="content-video" key={`video-${index}`}>
                                                <div className="embed-container">
                                                    {
                                                    item.video_type==='vimeo' &&
                                                    <iframe src={`https://player.vimeo.com/video/${item.video}?title=0&byline=0&portrait=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='youtube' &&
                                                    <iframe src={`https://www.youtube.com/embed/${item.video}?rel=0&showinfo=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='facebook' &&
                                                    <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${item.video}`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Div>
                                    ))}

                                    {participantes.length>0 &&
                                    <>
                                        <Row>
                                            <Col size={{ xs: 12, md: 12 }}>
                                                <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">
                                                    {(post.id=='219e5e13-e1a4-4340-92fa-0e1a21b720dd' || post.id=='2198b545-1cbe-4f82-b5db-f52dd236fc97' || post.id=='a8cc3d53-7747-4309-b731-3049d001d7f0' || post.id=='bd7a9399-d373-4d3a-8207-8cb31694967f') ?
                                                    <>
                                                        {participantes.length==1 ? language.title_autor : language.title_autores}
                                                    </>:
                                                    <>
                                                        {(post.id=='3a7e453a-cfdb-4d17-89e3-6f990bdf8da4' || post.id=='8c0468be-a4d8-47f0-bb10-a247a6a4ab9a' || post.id=='39f1cd6d-4a93-46e2-86c2-48404e761621' || post.id=='57aa86f2-b780-4648-b876-c01a85b9e35d' || post.id=='064e3b6a-a7f8-406a-9875-9bab33931460') ?
                                                        <>
                                                            {participantes.length==1 ? language.title_autora : language.title_autoras}
                                                        </>:
                                                        <>
                                                            {(post.id=='85348087-47f7-4a4b-a347-7bc86c60d6bf' || post.id=='b83c6649-61ba-48f9-bdfc-5a75da2faf1f') ?
                                                            <></>:
                                                            <>
                                                                {(post.id=='364659c4-67bd-4543-b6c2-cbd1fb15e251') ?
                                                                <>
                                                                    {participantes.length==1 ? language.title_tradutor : language.title_tradutores}
                                                                </>:
                                                                <>
                                                                    {participantes.length==1 ? language.title_participante : language.title_participantes}
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </>}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row align="flex-start" justify="center">
                                            {participantes.map(item => (
                                            <>
                                                <Col key={item.id} p="10px" size={{ xs: 6, md: 4 }}>
                                                    {post.id=='85348087-47f7-4a4b-a347-7bc86c60d6bf' &&
                                                    <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">
                                                        {item.id=='24c368c2-084b-4da6-b691-e6d5c4400cb3' ?
                                                        <>
                                                            {language.title_autor}
                                                        </>:
                                                        <>
                                                            {language.title_ilustrador}
                                                        </>}
                                                    </Text>}
                                                    {post.id=='b83c6649-61ba-48f9-bdfc-5a75da2faf1f' &&
                                                    <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">
                                                        {item.id=='b784c802-3f27-4e85-9e12-95f56edeb00b' ?
                                                        <>
                                                            {language.title_autora}
                                                        </>:
                                                        <>
                                                            {language.title_ilustradora}
                                                        </>}
                                                    </Text>}
                                                    {item.image!="" && <Image
                                                    onClick={() => {
                                                            setShowModal(true);
                                                            setTextAbout(item.about)
                                                    }}
                                                    shadow="3"
                                                    rounded="circle"
                                                    src={BaseSquare}
                                                    className="cover-bg"
                                                    style={{cursor: 'pointer',backgroundImage: `url(${item.image})`}} />}
                                                    <Text p={{ t: '4px' }} textSize="subheader" textColor="black600" textAlign="center">{item.name}</Text>
                                                    {item.sub!='' &&<Text p={{ b: '6px' }} textSize="paragraph" textColor="black100" textAlign="center">{item.sub}</Text>}
                                                    {item.id=='7fd251e3-17c1-41ac-a6cb-b8754c037df6' &&
                                                    <Text p={{ b: '6px' }} textSize="caption" textColor="gray900" textAlign="center">
                                                        Foto: Marcelo Correa
                                                    </Text>}
                                                    {item.id=='46e88946-a3b1-490d-9874-2daa6a0cf25a' &&
                                                    <Text p={{ b: '6px' }} textSize="caption" textColor="gray900" textAlign="center">
                                                        Foto: @Domestika
                                                    </Text>}
                                                    <Button
                                                        h="2rem"
                                                        p={{ x: "0.75rem" }}
                                                        m="auto"
                                                        textWeight="600"
                                                        textSize="body"
                                                        textColor="gray900"
                                                        hoverTextColor="gray900"
                                                        bg="gray300"
                                                        hoverBg="gray400"
                                                        rounded="lg"
                                                        onClick={() => {
                                                            setShowModal(true);
                                                            setTextAbout(item.about)
                                                        }}
                                                        disabled={item.about=='' ? true : false}
                                                    >
                                                    Bio
                                                    </Button>
                                                </Col>
                                            </>))}
                                        </Row>
                                    </>
                                    }
                                    {mediadora.length>0 &&
                                    <>
                                        <Row>
                                            <Col size={{ xs: 12, md: 12 }}>
                                                <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">{language.title_mediadores}</Text>
                                            </Col>
                                        </Row>
                                        <Row align="flex-start" justify="center">
                                            {mediadora.map(item => (
                                            <Col key={item.id} p="10px" size={{ xs: 6, md: 4 }}>
                                                {item.image!="" && <Image shadow="3" rounded="circle" src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />}
                                                <Text p={{ t: '4px' }} textSize="subheader" textColor="black600" textAlign="center">{item.name}</Text>
                                                {item.id=='b180a770-4aee-4316-b8b6-9af6e89a96d8' &&
                                                <Text p={{ b: '6px' }} textSize="caption" textColor="gray900" textAlign="center">
                                                    Foto: Duplo Retrato Bia Wouk
                                                </Text>}
                                                <Button
                                                    h="2rem"
                                                    p={{ x: "0.75rem" }}
                                                    m="auto"
                                                    textWeight="600"
                                                    textSize="body"
                                                    textColor="gray900"
                                                    hoverTextColor="gray900"
                                                    bg="gray300"
                                                    hoverBg="gray400"
                                                    rounded="lg"
                                                    onClick={() => setShowModal(true)}onClick={() => {
                                                        setShowModal(true);
                                                        setTextAbout(item.about)
                                                    }}
                                                    disabled={item.about=='' ? true : false}
                                                >
                                                   Bio
                                                </Button>
                                            </Col>
                                            ))}
                                        </Row>
                                    </>
                                    }

                                    {book.length>0 &&
                                    <Row>
                                        <Col size={{ xs: 12, md: 12 }}>
                                            <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">{language.buy_book}</Text>
                                        </Col>
                                        {book.map(item => (
                                        <Col key={item.id} size={{ xs: 12, md: 8 }} justify="center" className="m-auto">
                                            <Div p={{ t: '30px' }} d="flex" align="flex-start" justify="center">
                                                <Div p={{ r: '10px' }}>
                                                    <Image shadow="2" rounded="8px" style={{width: '160px'}} src={item.image} />
                                                </Div>
                                                <Div p={{ l: '10px' }} textAlign="left">
                                                    <Text textColor="black800" textSize="title" style={{fontStyle: 'italic'}}>{item.title}</Text>
                                                    <Div p="5px"></Div>
                                                    {item.link!=null ?
                                                    <Anchor href={item.link} target="_blank">
                                                        <Button
                                                            suffix={
                                                                <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                                            }
                                                            bg="black600"
                                                            hoverBg="black400"
                                                            shadow="2"
                                                            hoverShadow="3"
                                                            cursor="pointer"
                                                        >
                                                            {language.buy}
                                                        </Button>
                                                    </Anchor>:
                                                    <Button
                                                        suffix={
                                                            <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                                        }
                                                        bg="black600"
                                                        shadow="2"
                                                        cursor="pointer"
                                                        disabled={true}
                                                    >
                                                        {language.buy}
                                                    </Button>
                                                    }
                                                    <Div p="5px"></Div>
                                                    <Link to={`/books`} className="b-link">
                                                        <Text tag="span" textSize="subheader">{language.all_books}</Text>
                                                    </Link>
                                                </Div>
                                            </Div>
                                        </Col>
                                        ))}
                                    </Row>
                                    }
                            </>}
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}