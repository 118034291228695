import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Button } from "atomize";
import { BiChevronRight } from "react-icons/bi";

import LanguageContext from '../contexts/language';
import Base from '../assets/images/blank-900.png';

export default function Books(props) {

    const { language } = useContext(LanguageContext);

    const { content, isHome, isSearch } = props;

    return (
        <>
        {content.length>0 ?
        <Div bg="gray200" p={{ t: (isHome || isSearch) ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center">{language.title_books}</Text>
                    </Col>
                </Row>
                <Row justify="center">
                    {content.map(item => (
                    <Col key={item.id} p="20px" size={{ xs: 6, md: 3 }}>
                        <Link to={`/book/${item.url}`} className="no-underline menu-opacity">
                            <Image shadow="3" rounded="8px" src={Base} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                            <Text p={{ t: '10px' }} textSize="subheader" textColor="black600" textAlign="center" className="text-line">{item.title}</Text>
                        </Link>
                        <Div d="flex" align="center" justify="center">
                            <Link to={`/author/${item.author_url}`} className="link">
                                <Text textSize="paragraph" textColor="gray700" textAlign="center">{item.author_name}</Text>
                            </Link>
                            {item.author_url_2!='' &&
                            <>
                                <Text m={{x: '5px'}} textSize="paragraph" textColor="gray700" textAlign="center">-</Text>
                                <Link to={`/author/${item.author_url_2}`} className="link">
                                    <Text textSize="paragraph" textColor="gray700" textAlign="center">{item.author_name_2}</Text>
                                </Link>
                            </>}
                        </Div>
                    </Col>
                    ))}
                </Row>
                {isHome &&
                <Row>
                    <Col size={{ md: 'auto' }} p={{ t: "40px", b: "40px"}} textAlign="center">
                        <Link to={`/books`} className="btn_all">
                            {language.btn_all} <BiChevronRight size={22} style={{marginLeft: '6px'}} />
                        </Link>
                    </Col>
                </Row>
                }
            </Container>
        </Div>:
        <></>
        }
        </>
    );
}