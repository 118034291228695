import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Button } from "atomize";

import LanguageContext from '../contexts/language';

import s_logo_0 from '../assets/images/s_logo_0.png';
import s_logo_1 from '../assets/images/s_logo_1.png';
import s_logo_2 from '../assets/images/s_logo_2.png';
import s_logo_3 from '../assets/images/s_logo_3.png';

import s_logo_4 from '../assets/images/logo_colab.png';
import s_logo_5 from '../assets/images/logo_nerdetcetera.png';

import logos_video from '../assets/images/logos_video.png';
import logos_video_en from '../assets/images/logos_video_en.png';

export default function Sponsors() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <>
        <Row align="center" justify="center" p={{ t: '80px', b: '60px' }}>
            <Col className="text-center m-auto" size={{ xs: 10, md: 2 }}>
                <Link to="/donation" className="no-underline menu-opacity">
                    <Button
                        className="text-center m-auto"
                        h="3.5rem"
                        p={{ x: "2.5rem" }}
                        textSize="body"
                        textWeight="700"
                        bg="black600"
                        hoverBg="black400"
                        shadow="2"
                        hoverShadow="3"
                        cursor="pointer"
                        w="100%"
                    >
                        {language.donation}
                    </Button>
                </Link>
            </Col>
        </Row>
        <Div m={{ t: '30px' }}>
            <Image m={{ b: '20px' }} src={lang=='pt' ? logos_video : logos_video_en } />
            <Div p={{ t: '300px', b: '300px' }} className="bg-footer"></Div>
        </Div>
        </>
    );
}