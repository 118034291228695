import React, { createContext, useState, useEffect } from 'react';
import detectBrowserLanguage from 'detect-browser-language';

const LanguageContext = createContext();

const languages = {
    pt: {
        language: "pt",
        language_pt: "Português",
        language_en: "Inglês",
        language_sigla: "PT-BR",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        country_br: "Brasil",
        country_us: "Estados Unidos",
        menu_home: "Home",
        menu_welcome: "Boas Vindas",
        menu_schedule: "Programação",
        menu_author: "Autores",
        menu_news: "Novidades",
        menu_contact: "Contato",
        menu_search: "Buscar",
        menu_about: "Sobre",
        menu_terms: "Termos de Uso",
        menu_privacy: "Política de Privacidade",
        menu_account: "Minha Conta",
        menu_authors: "Autores",
        menu_books: "Livros",
        menu_logout: "Sair",
        menu_library: "Livraria",
        menu_purchases: "Minhas Compras",
        comments: "Comentários e Perguntas",
        no_comments: "Nenhum comentário ou pergunta.",
        comment_error: "Comentário não pode ser vázio!",
        comment_success: "Comentário adicionado com sucesso!",
        password: "Senha",
        r_password: "Confirmar Senha",
        n_password: "Nova Senha",
        remember: "Lembrar-me",
        no_data: "Preencha os campos obrigatórios!",
        no_data_register: "Todos os campos devem ser preenchidos!",
        no_data_login: "Preencha com seu E-mail e Senha!",
        no_terms: "Você precisa aceitar os Termos de Uso & Política de Privacidade",
        error_zipcode: "CEP não encontrado!",
        register_success: "Cadastro realizado com sucesso!",
        login_error: "E-mail ou senha inválidos!",
        create_error: "Erro ao Criar Conta!",
        form_success: "mensagem enviada com sucesso!",
        form_success_subscribe: "inscrição realizada com sucesso!",
        no_account: "Não tem uma conta?",
        has_account: "Já tem uma conta?",
        create_account: "Criar Conta",
        create_account_free: "CRIAR CONTA GRATUITA",
        form_name: "Nome",
        form_email: "E-mail",
        form_phone: "WhatsApp",
        form_message: "Mensagem",
        form_send: "ENVIAR",
        form_sending: "ENVIANDO...",
        form_subscribe: "Inscreva-se",
        form_contact: "contate-nos",
        form_creating: "Criando sua conta",
        form_login: "Login",
        form_loging: "Acessando",
        form_loading: "Aguarde...",
        form_capture: "Processando pagamento...",
        form_zipcode: "CEP",
        form_address: "Endereço",
        form_number: "Número",
        form_complement: "Complemento",
        form_neighborhood: "Bairro",
        form_city: "Cidade",
        form_state: "Estado",
        form_country: "País",
        form_whatsapp: "Celular / Telefone",
        form_save: "Salvar",
        form_saving: "Salvando...",
        form_terms: "Eu aceito os",
        optional: "Opcional",
        more: "Saiba Mais",
        watch: "Assistir Filme",
        title_404: "Ooops! Página não encontrada!",
        content_404: "Desculpe! A página que você estava procurando foi movida ou não existe.",
        btn_404: "Ir para a Home",
        btn_error: "Ir para Compras",
        not_available: " Indisponível no seu País",
        not_available_br: "Indisponível no Brasil",
        not_available_buy: "Você precisa obter All Access",
        error_masterclass: "Por favor, escolha pelo menos uma Masterclass!",
        error_payment: "Erro ao processar seu pagamento!",
        payment_message_1: "Pagamento realizado com sucesso.",
        payment_message_1_1: "Transação está sendo processada.",
        payment_message_2: "Seu pagamento foi processado com sucesso!",
        payment_message_2_1: "Seu pagamento está sendo processado pelo sistema de pagamentos!",
        payment_message_3: "Clique no botão abaixo para visualizar os detalhes da sua compra.",
        buy: "COMPRAR",
        author: "Autor",
        genre: "Gênero",
        forgot_password: "Esqueceu a senha?",
        forgot_reset: "Redefinição de senha",
        forgot_send: "CONTINUAR",
        forgot_search: "Procurando sua conta...",
        forgot_back: "Voltar para o Login",
        forgot_send_pin: "Enviamos um email com seu PIN",
        forgot_not_found: "E-mail não foi encontrado",
        forgot_error_pin: "PIN inválido!",
        error: "Ocorreu um erro, tente novamente!",
        email_error: "E-mail já está sendo usado por outra conta!",
        forgot_success: "Senha alterado com sucesso!",
        copyright: "Todos os Direitos Reservados.",
        title_event: "Eventos",
        title_panel: "Painéis",
        title_author: "Autores",
        title_author_special: "Participações Especiais",
        title_books: "Livros",
        title_news: "Novidades",
        title_sponsors: "Patrocinadores",
        btn_all: "Ver Todos",
        developed: "Desenvolvido por",
        phone_pt: "Telefone Brasileiro",
        phone_en: "Telefone Americano",
        title_participantes: "Participantes",
        title_participante: "Participante",
        title_autores: "Autores",
        title_autor: "Autor",
        title_autora: "Autora",
        title_autoras: "Autoras",
        title_ilustrador: "Ilustrador",
        title_ilustradora: "Ilustradora",
        title_mediadores: "Mediação",
        title_tradutor: "Tradutor",
        title_tradutores: "Tradores",
        now: "Agora",
        now_2: "Domingo, 21 nov, às 09:45h Miami (11:45h Brasília)",
        next: "A Seguir",
        all_books: "Explore os livros de todos os autores do FLIMIAMI",
        change_photo: "Alterar Foto",
        sponsors_1: "Apresentação",
        sponsors_2: "Apoio",
        sponsors_3: "Realização",
        tutorial: "Como me registrar",
        donation: "Doação",
        donation_amout: "Valor da Doação",
        shipping: "Frete",
        pay_with: "Pagar com",
        paypal: "Paypal",
        credit_card: "Cartão de Crédito",
        credit_card_payment: "Pagamento com Cartão de Crédito",
        pay: "Pagar",
        buy_book: "Compre o livro mencionado nesse evento.",
        card_number: "Número do Cartão",
        card_date: "Válidade",
        card_cvv: "CVV",
        card_zipcode: "CEP",
        card_date_p: "MM/AA",
        participating: "Participação nos Eventos",
        payment_message_1: "Pagamento realizado com sucesso.",
        payment_message_1_1: "Transação está sendo processada.",
        payment_message_2: "Seu pagamento foi processado com sucesso!",
        payment_message_2_1: "Seu pagamento está sendo processado pelo sistema de pagamentos!",
        payment_message_3: "Clique no botão abaixo para visualizar os detalhes da sua compra.",
        payment_message_4: "ACESSAR COMPRA",
        title_results: "Resultados por",
        title_bookfeatured: "Lançamento CCBU",
        text_bookfeatured: "BRAZILinMIAMI registra como as artes, a arquitetura, a música, a dança, a língua, os negócios, as celebrações culturais do Brasil e os próprios brasileiros tornaram-se parte integrante da vibrante mistura cultural de Miami. O livro é um projeto do Centro Cultural Brasil-USA da Flórida (CCBU) e foi organizado por Adriana Sabino.",
        google_calendar: "Google Agenda",
        google_calendar_text: "Deseja adicionar o evento ao Google Agenda?",
        google_calendar_not: "Não, obrigado",
        google_calendar_yes: "Sim, adicionar",
        google_calendar_btn: "Adicionar na agenda",
    },
    en: {
        language: "en",
        language_pt: "Portuguese",
        language_en: "English",
        language_sigla: "EN",
        language_pt_inverse: "Português",
        language_en_inverse: "English",
        country_br: "Brazil",
        country_us: "United States",
        menu_home: "Home",
        menu_welcome: "Welcome",
        menu_schedule: "Schedule",
        menu_author: "Authors",
        menu_news: "News",
        menu_contact: "Contact",
        menu_search: "Search",
        menu_about: "About",
        menu_terms: "Terms of Use",
        menu_privacy: "Privacy Policy",
        menu_account: "My Account",
        menu_authors: "Authors",
        menu_books: "Books",
        menu_logout: "Logout",
        menu_library: "Bookstore",
        menu_purchases: "My Purchases",
        comments: "Comments and Questions",
        no_comments: "No comments or questions.",
        comment_error: "Comment cannot be empty!",
        comment_success: "Comment sent successfully!",
        password: "Password",
        r_password: "Repeat Password",
        n_password: "New Password",
        remember: "Remember-me",
        no_data: "Fill in the required fields!",
        no_data_register: "All fields must be filled!",
        no_data_login: "Enter your E-mail and Password!",
        no_terms: "You need to accept the Terms of Use & Privacy Policy",
        error_zipcode: "Zipcode not found!",
        register_success: "Registration successful!",
        login_error: "Invalid email or password!",
        create_error: "Error creating your account!",
        form_success: "Message sent successfully!",
        form_success_subscribe: "subscription successful!",
        no_account: "Don't have account?",
        has_account: "Do you have account?",
        create_account: "Create Account",
        create_account_free: "CREATE FREE ACCOUNT",
        form_name: "Name",
        form_email: "Email",
        form_phone: "Phone Number",
        form_message: "Message",
        form_send: "SEND",
        form_sending: "SENDING...",
        form_subscribe: "Subscribe",
        form_contact: "contact us",
        form_creating: "Creating your account",
        form_login: "Login",
        form_loging: "Logging in",
        form_loading: "Loading...",
        form_capture: "Processing payment...",
        form_zipcode: "Zipcode",
        form_address: "Address",
        form_number: "Number",
        form_complement: "Complement",
        form_neighborhood: "Neighborhood",
        form_city: "City",
        form_state: "State",
        form_country: "Country",
        form_whatsapp: "Cell / Phone",
        form_save: "Save",
        form_saving: "Saving...",
        form_terms: "I Agree to",
        optional: "Optional",
        more: "Read More",
        watch: "Watch Movie",
        title_404: "Ooops! Page not found!",
        content_404: "Sorry! The page you were looking for has been moved or doesn't exist.",
        btn_404: "Go to Homepage",
        btn_error: "Go to Purchases",
        not_available: "Not available in your Country",
        not_available_br: "Not available in Brazil",
        not_available_buy: "You need to get All Access",
        error_masterclass: "Please choose at least one Masterclass!",
        error_payment: "Error processing your payment!",
        payment_message_1: "Payment successful.",
        payment_message_1_1: "Transaction is being processed.",
        payment_message_2: "Your payment has been successfully processed!",
        payment_message_2_1: "Your payment is being processed by the payment system!",
        payment_message_3: "Click the button below to view your purchase details.",
        buy: "BUY",
        author: "Author",
        genre: "Genre",
        forgot_password: "Forgot Password?",
        forgot_reset: "Password Reset",
        forgot_send: "CONTINUE",
        forgot_search: "Searching your account...",
        forgot_back: "Back to Login",
        forgot_send_pin: "We sent an email with your PIN",
        forgot_not_found: "Email not found",
        forgot_error_pin: "Invalid PIN",
        error: "An error has occurred, please try again!",
        email_error: "Email is being used by another account!",
        forgot_success: "Password changed successfully!",
        copyright: "All Rights Reserved.",
        title_event: "Events",
        title_panel: "Panels",
        title_author: "Authors",
        title_author_special: "Special Participants",
        title_books: "Books",
        title_news: "News",
        title_sponsors: "Sponsors",
        btn_all: "See All",
        developed: "Developed by",
        phone_pt: "Brazilian Phone",
        phone_en: "American Phone",
        title_participantes: "Participants",
        title_participante: "Participant",
        title_autores: "Authors",
        title_autor: "Author",
        title_autora: "Author",
        title_autoras: "Authors",
        title_ilustrador: "Illustrator",
        title_ilustradora: "Illustrator",
        title_mediadores: "Mediation",
        title_tradutor: "Translator",
        title_tradutores: "Translators",
        now: "Now",
        now_2: "Sunday, nov 21, at 09:45h Miami (11:45h Brasília)",
        next: "Next",
        all_books: "Browse books by all Festival Authors",
        change_photo: "Change Photo",
        sponsors_1: "Presenter Sponsors",
        sponsors_2: "Support",
        sponsors_3: "Produced by",
        tutorial: "How to register",
        donation: "Donate",
        donation_amout: "Donation Amount",
        shipping: "Shipping",
        pay_with: "Pay with",
        paypal: "Paypal",
        credit_card: "Credit Card",
        credit_card_payment: "Credit Card Payment",
        pay: "Pay",
        buy_book: "Buy the book associated with this event.",
        card_number: "Card Number",
        card_date: "Expiration",
        card_cvv: "CVV",
        card_zipcode: "Postal Code",
        card_date_p: "MM/YY",
        participating: "Participating Events",
        payment_message_1: "Payment successful.",
        payment_message_1_1: "Transaction is being processed.",
        payment_message_2: "Your payment has been successfully processed!",
        payment_message_2_1: "Your payment is being processed by the payment system!",
        payment_message_3: "Click the button below to view your purchase details.",
        payment_message_4: "ACCESS PURCHASE",
        title_results: "Results for",
        title_bookfeatured: "CCBU Book Release",
        text_bookfeatured: "BRAZILinMIAMI registers how the arts, the architecture, the music, the dance, the gastronomy, the businesses, the cultural celebrations from Brazil and the Brazilians themselves became part of Miami’s vibrant cultural mix. The book is a project by the Brazil-USA Cultural Center of Florida (CCBU), organized by Adriana Sabino.",
        google_calendar: "Google Calendar",
        google_calendar_text: "Do you want to add the event to Google Calendar? ",
        google_calendar_not: "No, thanks",
        google_calendar_yes: "Yes, add",
        google_calendar_btn: "Add to calendar",
    }
}

export const LanguageProvider = ({ children }) => {
    
    const [language, setLanguage] = useState({});

    async function loadStorage() {
        const storageLanguage = localStorage.getItem('language');
        if (!storageLanguage) {
            /*let browserLang = detectBrowserLanguage().split("-");
            if(browserLang[0]==='en'){
                setLanguageStore('en');
            }else{
                setLanguageStore('pt');
            }*/
            setLanguageStore('pt');
        }else{
            setLanguageStore(storageLanguage);
        }
    }

    async function setStorage(lang) {
        localStorage.setItem('language', lang);
    }

    function setLanguageStore(lang){
        if(lang==="pt" || lang==="PT"){
            setLanguage(languages.pt);
            setStorage("pt");
        }else{
            setLanguage(languages.en);
            setStorage("en");
        }
    }

    useEffect(() => {
        loadStorage();
    });

    return (
        <LanguageContext.Provider value={{ language, setLanguage, setLanguageStore }} >
            {children}
        </LanguageContext.Provider>
    )

}

export default LanguageContext;